import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import ContentImg from "../../components/GSTTemp/ContentImg";
import SliderContent from "../../components/GSTTemp/SliderContent";
import Package from "../../components/GSTTemp/Package";

export default function TrademarkObjection() {
  /* Slider */
  // const objSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online Trademark Objection`,
  //     content: `Best Trademark Objection Service Platform`,
  //     image: "/imgs/registration/msme/ms-me-reg.png",
  //     alt_tag: "Best Online Trademark Objection Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Trademark Objection`,
    buyBtnLink: `#pricing-buy`,
    price: `2999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/bregistration/pancard.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/bregistration/register.png",
        alt_tag: "Professional Tax Service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `Free Trademark
        Search Report`,
        icon: "/imgs/icons/bregistration/audit.png",
        alt_tag: "Trademark Search Report",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `10% discount on 4+ 
          applications`,
        icon: "/imgs/icons/it/80u.png",
        alt_tag: "Discount",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const objAboutData = {
    header: `Online Trademark Objection`,
    sub_title: `Register trademark objection with the help of TODAYFILINGS's professional experts.`,
    content_paragraph: [
      `When a trademark is applied for registration, it might be challenged by 
      the examiner, the public, or any other third party. It happens during the 
      preliminary stage of the trademark registration procedure when the examiner
      objects to the trademark application for a variety of reasons. The reason for 
      rejection cannot be the objection in any event, the registrar 
      seeks additional explanations and clarification concerning 
      the logo and its legitimacy.`,

      // `The registrar searches for more explanations and clarification regarding
      //  the logo and its legitimacy in any instance; the objection cannot be the
      //  reason for rejection. In the interest of the general public, the third party
      //   may also oppose to the trademark.`
    ],
  };

  /* Scroll Nav Data */
  const objScrollId = [
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#objection`,
      heading: `Objection Agenda`,
    },
    {
      id: `#documentss`,
      heading: `Documents`,
    },
    {
      id: `#purpose`,
      heading: `Purpose`,
    },
  ];

  /* Img Content Component Data */
  const objIcData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Trademark Objection Procedure',
    image: '/imgs/registration/ipr/td3.png',
    alt_tag: 'trademark objection Procedure',
    points: [
      {
        content: `The trademark's status in the Indian Trademark Registry will be changed to "Objected" when an objection is made.
         The stages to filing a response in the event of a trademark objection procedure are as follows.`,
        icon: true,
      },
      {
        content: `Getting the application ready`,
        icon: true,
      },
      {
        content: `Creating trademark opposition responses`,
        icon: true,
      },
      {
        content: `Trial for a trademark`,
        icon: true,
      },
      {
        content: `The trademark journal article`,
        icon: true,
      },
      {
        content: `Registration`,
        icon: true,
      },
    ],
  };
  /* Content Form Component Data */
  const objCfData = {
    id: 'objection',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Trademark Objection Agenda',
    content: `The trademark examiner looks into the trademark application to make sure it complies with all applicable trademark laws. 
    An objection to a trademark application may arise for any of the following reasons.`,
    body: [
      {
        head: `Keeping track of wrong trademark forms`,
        points: `If the application is not submitted using the proper form, the examiner will raise an objected status in Trademark. 
        For instance, Form TM-4 is used to register collective marks for goods and businesses in any one class. Form TM-51 is used to register trademarks for use on a variety of goods and businesses.`,
        icon: true,
      },
      {
        head: `Incorrect applicant name for a trademark`,
        points: `Trademarks must be registered using the applicant's legal name, with the spellings being double-checked.`,
        icon: true,
      },
      {
        head: `Use of false and offensive terminology `,
        points: `Trademark names that contain deceptive language or that aim to defraud customers by overstating the features of a product or
         service will be refused. For instance, it might be quite misleading for a business to market chocolate flavours as "vanilla chocolates."
         Additionally, requests to register trademarks containing disparaging language will be rejected.`,
        icon: true,
      },
      {
        head: `Lack of information regarding goods/services`,
        points: `When the trademark application fails to specifically list the products and services of the company, the Trademark Examiner is
         very likely to reject the application due to the unclear information.`,
        icon: true,
      },
      {
        head: `Existence of the same mark`,
        points: `The anticipated trademark must not be confusingly similar to any marks already in use by the company. Under the Trademarks Act, 
        the Trademark Examiner may raise objections stating that it might cause confusion among the general public.`,
        icon: true,
      },
    ],
  };
  /*  Content Img Component Data */
  const objCiData = {
    id: 'purpose',
    background: 'main-content',
    mt_div: '',
    header: 'Purpose Of Trademark Objection',
    paragraph: ``,
    points: [
      {
        content: `The brand name analyst examines the application's 
        accuracy in an endless supply of it. The following reasons can be used to object to a trademark's status.`,
        icon: true,
      },
      {
        content: `Incorrect form.`,
        icon: true,
      },
      {
        content: `Derogatory terms.`,
        icon: true,
      },
      {
        content: `Unreliable applicant name.`,
        icon: true,
      },
      {
        content: `Use of deceptive language.`,
        icon: true,
      },
      {
        content: `The same logo is already in use.`,
        icon: true,
      },
      {
        content: `Insufficient product or service information.`,
        icon: true,
      },
    ],
    image: '/imgs/business/documents-one.png',
    alt_tag: 'Required Documents for Trademark Objection',
  };

  /* ImgSlider Data */
  const objImages = [
    '/imgs/registration/ipr/td8.png',
    '/imgs/registration/ipr/td4.png',
    '/imgs/registration/ipr/td2.png',
  ];

  /*  Slider Content Component Data */
  const objSCData = {
    id: 'documentss',
    background: [],
    mt_div: 'mt-3',
    header: 'Documents Required For Trademark Objection',
    content: [
      {
        head: `The necessary documents will be supplied after a review of your inquiries:`,
        points: ``,
        icon: false,
      },
      {
        points: `MSME Certificate`,
        icon: true,
      },
      {
        points: `Registration address`,
        icon: true,
      },
      {
        points: `Brand/Logo/Slogan name`,
        icon: true,
      },
      {
        points: `Identity proof and Business proofs`,
        icon: true,
      },
      {
        points: `Incorporation certificate and Registration certificate`,
        icon: true,
      },
    ],
    images: objImages,
  };

  var FAQ_data = [
    {
      header: 'How will I know if there is a problem with my trademark?',
      body: [
        {
          content: `You can check here to see where the trademark application stands.
          The status is going to say "Objected."`,
          icon: false,
        },
      ],
    },

    {
      header: 'When ought the response to be written and delivered?',
      body: [
        {
          content: `The answer must be written and submitted within 30 days of the
          date the evaluation report was issued.`,
          icon: false,
        },
      ],
    },
    {
      header: 'If the Hearing is unsuccessful, what happens?',
      body: [
        {
          content: `If it is unsuccessful, a Refusal Order that explains the reason for
          the refusal will be passed.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What happens if I continue running my company using a denied trademark?',
      body: [
        {
          content: `For legal action against infringers, trademark registration is
          necessary. It is usually a good idea to submit a new trademark
          application or, in some cases, to challenge a refused trademark.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What occurs if a person is unable to attend the hearing on the specified date?',
      body: [
        {
          content: `The applicant may ask for a hearing adjournment if he or she is
          unable to attend.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is meant by Trademark Objection?',
      body: [
        {
          content: `The examiner should be satisfied with the trademark it should comply with all the rules 
          and regulations, if the examiner is unsatisfied with the trademark he may object to the trademark.
           An intimation will be sent to the applicant and the applicant is required to respond within thirty days.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Where to get the Trademark Objection report?',
      body: [
        {
          content: `The trademark examination report of the objection over trademark on the website of IPI India.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How much time does it take to reply to a Trademark objection?',
      body: [
        {
          content: `The trademark experts draft and file a reply within 3 days of raising an objection 
          that is received subject to the available documents.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Is there any difference between Trademark objection and Trademark Opposition?',
      body: [
        {
          content: `The trademark objection is raised by the examiner by raising questions over the criteria
           that are followed during registration whereas the opposition is done by a third party over the credibility 
           of the trademark.`,
          icon: false,
        },
      ],
    },
    {
      header: 'When does a trademark get Objection?',
      body: [
        {
          content: `A trademark is objected to due to many reasons like the documents filed or the fulfillment of the 
          criteria that is mandated by the rules.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Trademark Objection'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={objSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={objAboutData} />
          <Counter />
          <ScrollNav scroll_data={objScrollId} />
          <ImgContent item={objIcData} />
          <ContentForm CFSection_data={objCfData} />
          <SliderContent ScSection_data={objSCData} />
          <ContentImg CISection_data={objCiData} />
          <Guidance />
          <Cta />
          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
